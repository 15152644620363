%transition {
  transition: 0.3s ease-in-out;
}

%btn {
  @apply flex
  justify-center
  items-center
  cursor-pointer
  hover:opacity-70;
}

.custom {
  @extend %transition, %btn;
}

.modal {
  max-width: 8.5rem;
  width: 100%;
  @extend %transition, %btn;
}

.default {
  @apply border-solid
  border
  border-transparent
  bg-primary;
  @extend %transition, %btn;
}

.withoutBg {
  @apply border-solid
  border
  border-transparent;
  @extend %transition, %btn;
}

.secondary {
  @apply border-solid
  border
  border-transparent
  bg-silver;
  @extend %transition, %btn;
}

.disabled {
  @extend %transition, %btn;
  @apply bg-silver pointer-events-none border-transparent border-solid
  border;
}

.empty {
  @apply border-dawn-gray
  border
  border-solid
  bg-transparent;
  @extend %transition, %btn;
}

.empty-borderless {
  @apply border-none
  bg-beige;
  @extend %transition, %btn;
}

.edit {
  @apply border-transparent
  bg-primary;
  @extend %transition, %btn;
  &:default {
    @apply bg-silver;
  }
  @extend %transition, %btn;
}

.astext {
  @apply bg-none
  border-none
  m-0
  p-0
  cursor-pointer;
}

.green {
  @apply bg-green
  border-none;
  @extend %transition, %btn;
}

.transparent {
  border: 2px solid darkgray;
  background-color: transparent;

  @extend %transition, %btn;
}
