.reportModal {
  min-width: 44.75rem;
  max-width: 63.313rem;
  height: max-content;
}

.reportModalSize {
  overflow: auto;
  max-height: calc(100vh - 300px);
}

.calendarShadow {
  box-shadow: 0 0 0.125rem #e7e4e3, 0 2rem 2.5rem rgba(24, 16, 9, 0.06);
  top: -85%;
}

.OptionsBox div {
  border: 2px solid #d3d3d3;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 50px;
}

.OptionsBox {
  text-align: center;
  cursor: pointer;
}

.OptionsBox.selectedBox div {
  border-color: #585550;
  background-color: #f4f1ed;
}
