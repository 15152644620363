.p-dialog {
  .p-dialog-footer {
    button {
      border-radius: 9999px;
      &:not(.p-button-text) {
        background-color: rgba(252,185,107,1);
        border-color:  rgba(252,185,107,1);
        outline: 0;
        box-shadow: none;
        &:hover {
          opacity: 0.7;
        }
      }
      &.p-button-text {
        color:rgba(252,185,107,1);
        box-shadow: none;
        &:hover {
          color:rgba(252,185,107,1);
          background-color:rgba(252,185,107,0.1);
        }
      }
    }
  }
}
