%transition {
  transition: 0.3s ease-in-out;
}

.nav {
  @apply bg-main
  flex
  flex-col
  items-center
  justify-between;
  min-width: 9rem;
  width: 155px;
  text-align: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: row;
    width: 100%;
    position: absolute;
    z-index: 1000;
    bottom: 0;
    margin-top: 5px;
    margin-bottom: 0;
    padding: 0 15px 0 0;
    box-shadow: 0px 0px 1rem #e7e4e3, 0px 32px 40px rgba(24, 16, 9, 0.06);
  }
}

.navbar {
  @apply flex-col;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    padding: 0 15px 0 0;
  }
}

%navItem {
  @apply relative
  flex
  flex-col
  justify-center
  items-center
  p-4
  px-5
  w-full
  text-dawn-gray
  text-base
  cursor-pointer;
}

.navItemDefault {
  @apply bg-main
  opacity-80
  hover:bg-opacity-50
  hover:bg-pampas;
  @extend %navItem, %transition;
}

.navItemActive {
  @apply font-medium-poppins
  text-dawn-gray
  bg-pampas;
  @extend %navItem;
}

.navList {
  max-height: calc(100vh - 320px);
  overflow: auto;
  margin-bottom: 20px;
}
